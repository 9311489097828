import React, { useState, useEffect } from "react";
import { isEmail } from 'validator';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, TextField, Button } from "@material-ui/core";
import Layout from '../components/Layout';
import ProgressButton from '../components/ButtonLoading';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from "react-redux";
import { actions } from '../services/support';
import { RootState } from "../store";
import { BooleanParam, useQueryParams } from "use-query-params";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0)
      },
      content: {
        maxWidth: 515,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }
  ),
);

const PhoneInput = ({ ...props }) => {
  return <InputMask {...props} mask="+1 999 999 9999" />
}

interface FormState {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactUs: React.FC = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    support: state.support,
  }));

  const dispatch = useDispatch();

  const [ form, setForm ] = useState<FormState>({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [ params, setParams ] = useQueryParams({
    sent: BooleanParam
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (state.user) {
      setForm({
        ...form,
        name: `${state.user.firstName || ''} ${state.user.lastName || ''}`,
        email: state.user.email || '',
        phone: state.user.phone || '',
      })
    }
  }, [ Boolean(state.user) ]);

  const [ errors, setErrors ] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  const validateForm = () => {
    let valid = true;
    const err = {
      name: '',
      email: '',
      phone: '',
      message: '',
    };

    if (!form.name) {
      err.name = 'Name is required';
      valid = false;
    }
    if (!isEmail(form.email)) {
      err.email = 'Invalid email';
      valid = false;
    }

    if (!form.message) {
      err.message = 'Message is required';
      valid = false;
    }

    setErrors(err);
    return valid;
  }

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [ name ]: event.target.value });
  };

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (validateForm()) {
      await dispatch(actions.sendMessage(form));
      setParams({ sent: true }, 'replace');
    }
  };


  return (
    <Layout contentClassName={classes.root}>
      <Container maxWidth="xs" className={classes.content}>
        <Typography variant="h5" align="center" paragraph>Contact Us</Typography>
        {
          params.sent ? (<Typography align="center">Thank you for contacting us. We will get back to you as soon as possible.</Typography>) : (
            <React.Fragment>
              <Typography align="center" variant="body2">Feel free to contact us, if you have any questions or need help with our service.</Typography>
              <form className={classes.form} noValidate onSubmit={onSubmitForm}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Your name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  required
                  value={form.name}
                  onChange={handleChange('name')}
                  error={errors.name !== ''}
                  helperText={errors.name}
                  disabled={state.support.loading}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email address"
                  name="email"
                  autoComplete="email"
                  required
                  value={form.email}
                  onChange={handleChange('email')}
                  error={errors.email !== ''}
                  helperText={errors.email}
                  disabled={state.support.loading}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="phone"
                  label="Phone (optional)"
                  name="phone"
                  autoComplete="phone"
                  InputProps={{
                    inputComponent: PhoneInput as any,
                  }}
                  value={form.phone}
                  onChange={handleChange('phone')}
                  error={errors.phone !== ''}
                  helperText={errors.phone}
                  disabled={state.support.loading}
                />

                <TextField
                  multiline
                  rows={5}
                  rowsMax={7}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="message"
                  label="Message"
                  name="message"
                  required
                  value={form.message}
                  onChange={handleChange('message')}
                  error={errors.message !== ''}
                  helperText={errors.message}
                  disabled={state.support.loading}
                />

                <ProgressButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  loading={state.support.loading}
                >
                  Send
            </ProgressButton>
              </form>
            </React.Fragment>
          )
        }

      </Container>
    </Layout>
  );
};

export default ContactUs;